import { useIntl } from "react-intl";

import { Divider } from "../ui/layout/Divider";
import { H1 } from "../ui/Typo/Typo";
import List from "./List";

const Reports = () => {
  const { formatMessage: f } = useIntl();

  return (
    <>
      <div className="-ml-4 -mt-2 flex items-center justify-between flex-wrap sm:flex-no-wrap">
        <div className="ml-4 mt-2">
          <H1>{f({ id: "reports.name" })}</H1>
        </div>
        <div className="p-6 bg-white ml-4 mt-2 flex-shrink-0"></div>
      </div>

      <Divider />

      <List
        data={[
          { name: "Test", description: "Test" },
          { name: "Lorem", description: "Ipsum" },
        ]}
      />
    </>
  );
};

export default Reports;
