import { gql } from "@apollo/client";

export const FILTER_SKILL_MANY = gql`
  query(
    $name: String
    $color: String
    $core: Boolean
    $future: Boolean
    $forEmployee: Boolean
    $forManager: Boolean
  ) {
    skillMany(
      # filter: [{ field: "name", value: $name, operator: like }]
      filter: {
        name: $name
        color: $color
        core: $core
        future: $future
        forEmployee: $forEmployee
        forManager: $forManager
      }
      limit: 200
      sort: NAME_ASC
    ) {
      name
      description
      color
      costs
      expirationYear
      core
      future
      forEmployee
      forManager
      employeeCount
      jobCount
      seminarCount
      supply
      demand
      createdAt
      _id
    }
  }
`;

export const SKILL_MANY = gql`
  query($filter: SkillFilterInput) {
    skillMany(filter: $filter, limit: 200, sort: NAME_ASC) {
      name
      description
      color
      costs
      expirationYear
      core
      future
      forEmployee
      forManager
      employeeCount
      jobCount
      seminarCount
      supply
      demand
      createdAt
      _id
    }
  }
`;

export const SKILL_BY_ID = gql`
  query($_id: ID!) {
    skillById(_id: $_id) {
      name
      description
      color
      costs
      expirationYear
      skillTypeId
      skillType {
        name
      }
      core
      future
      forEmployee
      forManager
      employeeCount
      jobCount
      supply
      demand
      projectedSupply
      projectedDemand
      createdAt
      _id
    }
  }
`;

export const SKILL_CREATE_ONE = gql`
  mutation(
    $name: String!
    $description: String
    $color: String
    $costs: Float
    $expirationYear: Int
    $skillTypeId: ID!
    $core: Boolean
    $future: Boolean
    $forEmployee: Boolean
    $forManager: Boolean
  ) {
    skillCreateOne(
      input: {
        name: $name
        description: $description
        color: $color
        costs: $costs
        expirationYear: $expirationYear
        skillTypeId: $skillTypeId
        core: $core
        future: $future
        forEmployee: $forEmployee
        forManager: $forManager
      }
    ) {
      _id
      name
    }
  }
`;

export const SKILL_UPDATE_BY_ID = gql`
  mutation(
    $_id: ID!
    $name: String!
    $color: String
    $description: String
    $costs: Float
    $expirationYear: Int
    $skillTypeId: ID!
    $core: Boolean
    $future: Boolean
    $forEmployee: Boolean
    $forManager: Boolean
  ) {
    skillUpdateById(
      input: {
        name: $name
        description: $description
        color: $color
        costs: $costs
        expirationYear: $expirationYear
        skillTypeId: $skillTypeId
        core: $core
        future: $future
        forEmployee: $forEmployee
        forManager: $forManager
      }
      _id: $_id
    ) {
      _id
      name
    }
  }
`;

export const SKILL_REMOVE_BY_ID = gql`
  mutation($_id: ID!) {
    skillRemoveById(_id: $_id) {
      name
    }
  }
`;

export const SKILL_COUNT = gql`
  query {
    skillCount
  }
`;
