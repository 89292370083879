import { useMutation, useQuery } from "@apollo/client";
import { XCircleIcon } from "@heroicons/react/outline";
import React from "react";
import { useIntl } from "react-intl";

import { Error, Loading } from "components/ui/Alerts";
import SlideOver from "components/ui/SlideOver";
import { H1 } from "components/ui/Typo/Typo";
import { PermissionRequired } from "components/ui/permissions";
import SliderOverContext from "context/SliderOverContext";
import useToggle from "hooks/useToogle";
import { PERMISSION_MANY } from "schema/permission";
import { CREATE_ROLE, DELETE_ROLE, ROLE_MANY } from "schema/role";
import type { Permission } from "types/permission";
import { PermissionFlag } from "types/permission";
import type { Role } from "types/role";
import Tabs from "../users/Tabs";
import { DeleteRoleForm } from "./DeleteRoleForm";
import type { FormData as NewRoleFormData } from "./NewRoleForm";
import { NewRoleForm } from "./NewRoleForm";
import { PermissionTable } from "./PermissionTable";
import { findRole, permissionsByModule } from "./functions";

interface IState {
  roleSelected: Role | null;
}

export const PermissionsList: React.FC = () => {
  const { formatMessage: f } = useIntl();

  const selectRole = (roleSelected: Role) => {
    setState({ ...state, roleSelected });
  };
  const {
    data: permissionsData,
    loading: permissionsLoading,
    error: permissionsError,
  } = useQuery(PERMISSION_MANY);
  const {
    data: rolesData,
    loading: rolesLoading,
    error: rolesError,
  } = useQuery(ROLE_MANY);
  const [createRole] = useMutation(CREATE_ROLE, {
    refetchQueries: [{ query: ROLE_MANY }, { query: PERMISSION_MANY }],
    onCompleted: (data) => selectRole(data.createRole),
  });
  const [deleteRole] = useMutation(DELETE_ROLE, {
    refetchQueries: [{ query: ROLE_MANY }, { query: PERMISSION_MANY }],
    onCompleted: () => selectRole(rolesData.roleMany[0]),
  });
  const [openNew, toggleNew] = useToggle();
  const [openDelete, toggleDelete] = useToggle();
  const [state, setState] = React.useState<IState>({
    roleSelected: null,
  });

  const handleChangeRole = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const role = findRole(event.target.value)(rolesData?.roleMany || []);
    if (role) {
      selectRole(role);
    }
  };
  const handleNewRole = (formData: NewRoleFormData) => {
    createRole({
      variables: {
        role: { name: formData.name },
        inheritFrom: { roleId: formData.inheritFrom },
      },
    });
    toggleNew();
  };
  const handleDeleteRole = () => {
    if (!state.roleSelected) {
      return;
    }
    deleteRole({
      variables: {
        roleId: state.roleSelected._id,
      },
    });
    toggleDelete();
  };
  const byModule = permissionsByModule(state.roleSelected?._id || "");

  const loading = permissionsLoading || rolesLoading;
  const error = permissionsError || rolesError;

  React.useEffect(() => {
    if (!rolesData?.roleMany || state.roleSelected) {
      return;
    }
    setState({ ...state, roleSelected: rolesData.roleMany[0] });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rolesData?.roleMany]);

  return (
    <>
      {loading && <Loading />}
      {error && <Error error={error} />}

      <PermissionRequired
        module="admin.permissions"
        flag={PermissionFlag.WRITE}
      >
        <SliderOverContext.Provider
          value={{ open: openNew, toggle: toggleNew }}
        >
          <SlideOver
            title="Rolle hinzufügen"
            formId="new-rule-form"
            description="Hier können Sie eine neue Rolle hinzufügen."
          >
            <NewRoleForm
              roles={rolesData?.roleMany}
              onSubmit={handleNewRole}
              id="new-rule-form"
            />
          </SlideOver>
        </SliderOverContext.Provider>
      </PermissionRequired>

      <PermissionRequired
        module="admin.permissions"
        flag={PermissionFlag.WRITE}
      >
        <SliderOverContext.Provider
          value={{ open: openDelete, toggle: toggleDelete }}
        >
          <SlideOver
            title="Rolle löschen"
            formId="delete-rule-form"
            description="Hier können Sie eine neue Rolle hinzufügen."
          >
            {state.roleSelected ? (
              <DeleteRoleForm
                role={state.roleSelected}
                onSubmit={handleDeleteRole}
                id="delete-rule-form"
              />
            ) : null}
          </SlideOver>
        </SliderOverContext.Provider>
      </PermissionRequired>

      <Tabs />

      <div className="-ml-4 -mt-2 flex items-center justify-between flex-wrap sm:flex-no-wrap">
        <div className="ml-4 mt-2">
          <H1>{f({ id: "permissions.name" })}</H1>
        </div>
        <div className="p-6 bg-white ml-4 mt-2 flex-shrink-0">
          <PermissionRequired
            module="admin.permissions"
            flag={PermissionFlag.WRITE}
          >
            <span className="inline-flex rounded-md shadow-sm">
              <button
                type="button"
                className="relative inline-flex items-center px-4 py-2 mr-4 border border-transparent text-sm leading-5 font-medium rounded-md text-gray bg-gray-300 hover:bg-gray-200 focus:outline-none focus:shadow-outline-indigo focus:border-orange-400 active:bg-coral-400"
                onClick={toggleDelete}
                disabled={!state.roleSelected?.isEditable}
              >
                <XCircleIcon
                  className="group-hover:text-light-blue-600 text-light-blue-500 mr-2"
                  width="20"
                  height="20"
                />
                Rolle Löschen
              </button>

              <button
                type="button"
                className="relative inline-flex items-center px-4 py-2 border border-transparent text-sm leading-5 font-medium rounded-md text-gray bg-coral-300 hover:bg-coral-200 focus:outline-none focus:shadow-outline-indigo focus:border-orange-400 active:bg-coral-400"
                onClick={toggleNew}
              >
                <svg
                  className="group-hover:text-light-blue-600 text-light-blue-500 mr-2"
                  width="12"
                  height="20"
                  fill="currentColor"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M6 5a1 1 0 011 1v3h3a1 1 0 110 2H7v3a1 1 0 11-2 0v-3H2a1 1 0 110-2h3V6a1 1 0 011-1z"
                  />
                </svg>
                Neue Rolle
              </button>
            </span>
          </PermissionRequired>
        </div>
      </div>

      <div className="mt-3 flex flex-col space-y-6">
        <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
            <select
              className="mb-2"
              onChange={handleChangeRole}
              value={state.roleSelected?._id}
            >
              {(rolesData?.roleMany || []).map((r: Role) => (
                <option key={r._id} value={r._id}>
                  {r.name}
                </option>
              ))}
            </select>
            {byModule(permissionsData?.permissionMany || []).map(
              ([module, permissions]) => (
                <PermissionTable
                  key={module}
                  title={module}
                  role={state.roleSelected}
                  permissions={permissions as Permission[]}
                  isEditable={state.roleSelected?.isEditable}
                />
              )
            )}
          </div>
        </div>
      </div>
    </>
  );
};
