import React, { useEffect, useState } from "react";
import { createBrowserHistory } from "history";
import { BrowserRouter as Router, Switch, Redirect } from "react-router-dom";

import { IntlProvider } from "react-intl";
import axios from "axios";

import {
  ApolloProvider,
  ApolloClient,
  ApolloLink,
  createHttpLink,
  useReactiveVar,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";

import { authTypes } from "./schema/auth";
import { licenseTypes } from "./schema/license";
import { roleTypes } from "./schema/role";
import { localeTypes } from "./schema/locale";
import { cache, localeVar } from "./cache";
import { navItems } from "./nav";
import PrivateRoute from "./router/PrivateRoute";

import Header from "./components/ui/Header/Header";
import Footer from "./components/ui/sections/Footer";
import Home from "./components/Home";
import SkillsIndex from "./components/skills/Index";
import SkillDetails from "./components/skills/Details";
import SkillTypesIndex from "./components/skillTypes/Index";
import SkillTypeDetails from "./components/skillTypes/Details";
import SkillGroupsIndex from "./components/skillGroups/Index";
import SkillGroupDetails from "./components/skillGroups/Details";
import SkillScalesIndex from "./components/skillScales/Index";
import EmployeesIndex from "./components/employees/Index";
import EmployeeDetails from "./components/employees/Details";
import EmployeeProfile from "./components/employees/Profile";
import EmployeeMatch from "./components/employees/Match";
import EmployeeReview from "./components/employees/Review";
import EmployeeTraining from "./components/employees/Training";
import ReviewsIndex from "./components/reviews/Index";
import ReviewDetails from "./components/reviews/Details";
import ReviewForm from "./components/reviews/Form";
import JobsIndex from "./components/jobs/Index";
import JobDetails from "./components/jobs/Details";
import JobProfile from "./components/jobs/Profile";
import JobMatch from "./components/jobs/Match";
import JobVacancies from "./components/jobs/Vacancies";
import JobDescriptionsIndex from "./components/jobDescriptions/Index";
import JobDescriptionDetails from "./components/jobDescriptions/Details";
import OrganizationsIndex from "./components/organization/Index";
import OrganizationDetails from "./components/organization/Details";
import OrgChart from "./components/orgchart/Index";
import UnitsIndex from "./components/units/Index";
import UnitDetails from "./components/units/Details";
import TeamsIndex from "./components/teams/Index";
import TeamDetails from "./components/teams/Details";
import TeamMatch from "./components/teams/Match";
import RevenuesIndex from "./components/revenues/Index";
import UsersIndex from "./components/users/Index";
import UserDetails from "./components/users/Details";
import UserLogin from "./components/users/auth/Login";
import UserLogout from "./components/users/auth/Logout";
import UserSignup from "./components/users/auth/Signup";
import UserForgotPassword from "./components/users/auth/ForgotPassword";
import UserResetPassword from "./components/users/auth/ResetPassword";
import UserSettings from "./components/users/settings/Index";
import InvitationsIndex from "./components/invitations/Index";
import InvitationDetails from "./components/invitations/Details";
import RecruitingIndex from "./components/recruiting/Index";
import ApplicantsIndex from "./components/applicants/Index";
import ApplicantDetails from "./components/applicants/Details";
import ApplicantProfile from "./components/applicants/Profile";
import VacanciesIndex from "./components/vacancies/Index";
import VacancyDetails from "./components/vacancies/Details";
import VacancyProfile from "./components/vacancies/Profile";
import VacancyMatch from "./components/vacancies/Match";
import SeminarsIndex from "./components/seminars/Index";
import SeminarDetails from "./components/seminars/Details";
import SeminarTypesIndex from "./components/seminarTypes/Index";
import SeminarTypeDetails from "./components/seminarTypes/Details";
import ReportsIndex from "./components/reports/Index";
import MessagesIndex from "./components/messages/Index";
import Error from "./components/Error";
import { PermissionsList } from "components/permissions";
import { Route } from "components/ui/permissions";
import { PermissionFlag } from "types/permission";
// const ReportsIndex = lazy(() => import("./components/reports/Index"));

const history = createBrowserHistory();

const httpLink = createHttpLink({
  uri: process.env.REACT_APP_GRAPHQL_URI,
});

const cleanTypeName = new ApolloLink((operation, forward) => {
  if (operation.variables) {
    const omitTypename = (key, value) =>
      key === "__typename" ? undefined : value;
    operation.variables = JSON.parse(
      JSON.stringify(operation.variables),
      omitTypename
    );
  }
  return forward(operation).map((data) => {
    return data;
  });
});

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = localStorage.getItem("token");
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
    },
  };
});

export const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.map(({ message, locations, path }) =>
      console.log(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
      )
    );

  if (networkError) {
    if (networkError.statusCode === 401) {
      // Unauthorized
      if (/^\/users/.test(history.location.pathname) === false) {
        window.location.href = "/users/login";
      }
    } else {
      // history.push("/error");
    }
  }
});

const client = new ApolloClient({
  connectToDevTools: true,
  link: ApolloLink.from([cleanTypeName, errorLink, authLink, httpLink]),
  cache,
  typeDefs: [authTypes, licenseTypes, roleTypes, localeTypes],
});

const App = () => {
  //const [locale, setLocale] = useState(localeVar);
  const locale = useReactiveVar(localeVar);
  const [messages, setMessages] = useState({});

  const fetchMessages = async (locale) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_TRANSLATIONS_URI}/${locale}`
      );
      setMessages(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchMessages(locale);
    return () => {};
  }, [locale]);

  return (
    <ApolloProvider client={client}>
      <IntlProvider locale={locale} key={locale} messages={messages}>
        <Router>
          <Header navItems={navItems} title="8Skills" />

          <main>
            <div className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
              <div className="px-4 py-6 sm:px-0">
                <Switch>
                  <Route exact path="/">
                    <Home />
                  </Route>
                  <Route
                    exact
                    module="competencies.company.competencies"
                    path="/skills"
                    component={SkillsIndex}
                  />
                  <Route
                    module="competencies.company.competencies"
                    path="/skills/:id/details"
                    component={SkillDetails}
                  />
                  <Route
                    exact
                    module="competencies.competencies_types"
                    path="/skillTypes"
                    component={SkillTypesIndex}
                  />
                  <Route
                    module="competencies.competencies_types"
                    path="/skillTypes/:id/details"
                    component={SkillTypeDetails}
                  />
                  <Route
                    exact
                    module="competencies.competency_groups"
                    path="/skillGroups"
                    component={SkillGroupsIndex}
                  />
                  <Route
                    module="competencies.competency_groups"
                    path="/skillGroups/:id/details"
                    component={SkillGroupDetails}
                  />
                  <Route
                    exact
                    module="competencies.competency_scaling"
                    path="/skillScales"
                    component={SkillScalesIndex}
                  />
                  <Route exact path="/employees" component={EmployeesIndex} />
                  <Route
                    path="/employees/:id/details"
                    component={EmployeeDetails}
                    module="employees.details"
                  />
                  <Route
                    path="/employees/:id/profile"
                    component={EmployeeProfile}
                    module="employees.competency_profile"
                  />
                  <Route
                    path="/employees/:id/match"
                    component={EmployeeMatch}
                    module="employees.match"
                  />
                  <Route
                    exact
                    path="/employees/:id/review"
                    component={EmployeeReview}
                    module="employees.review"
                  />
                  <Route
                    path="/employees/:id/review/form"
                    component={ReviewForm}
                    module="employees.review"
                    flag={PermissionFlag.WRITE}
                  />
                  <Route
                    path="/employees/:id/training"
                    component={EmployeeTraining}
                    module="employees.further_education"
                  />
                  <Route
                    exact
                    module="employees.review"
                    path="/reviews/"
                    component={ReviewsIndex}
                  />
                  <Route
                    module="employees.review"
                    path="/reviews/:id/details"
                    component={ReviewDetails}
                  />
                  <Route
                    exact
                    module="jobs.show"
                    path="/jobs"
                    component={JobsIndex}
                  />
                  <Route
                    module="jobs.details"
                    path="/jobs/:id/details"
                    component={JobDetails}
                  />
                  <Route
                    module="jobs.competency_profiles"
                    path="/jobs/:id/profile"
                    component={JobProfile}
                  />
                  <Route
                    module="jobs.employee_matches"
                    path="/jobs/:id/match"
                    component={JobMatch}
                  />
                  <Route path="/jobs/:id/vacancies" component={JobVacancies} />
                  <Route
                    exact
                    path="/jobDescriptions"
                    component={JobDescriptionsIndex}
                  />
                  <Route
                    path="/jobDescriptions/:id/details"
                    component={JobDescriptionDetails}
                  />
                  <Route
                    exact
                    module="organization.organization"
                    path="/org"
                    component={OrganizationsIndex}
                  />
                  <Route
                    module="organization.organization"
                    path="/org/:id/details"
                    component={OrganizationDetails}
                  />
                  <Route
                    exact
                    module="organization.units"
                    path="/units"
                    component={UnitsIndex}
                  />
                  <Route
                    module="organization.units"
                    path="/units/:id/details"
                    component={UnitDetails}
                  />
                  <Route
                    exact
                    module="organization.organigram.revenue_matrix"
                    path="/revenues"
                    component={RevenuesIndex}
                  />
                  <Route exact path="/orgchart" component={OrgChart} />
                  <Route exact path="/teams" component={TeamsIndex} />
                  <Route path="/teams/:id/details" component={TeamDetails} />
                  <Route path="/teams/:id/match" component={TeamMatch} />
                  <Route
                    exact
                    module="users.show"
                    path="/users"
                    component={UsersIndex}
                  />
                  <Route
                    module="users.show"
                    path="/users/:id/details"
                    component={UserDetails}
                  />
                  <Route path="/users/login" component={UserLogin} />
                  <Route path="/users/logout" component={UserLogout} />
                  <Route
                    path="/users/signup/:signupToken?"
                    component={UserSignup}
                  />
                  <Route
                    path="/users/forgotPassword"
                    component={UserForgotPassword}
                  />
                  <Route
                    path="/users/resetPassword/:resetPasswordToken"
                    component={UserResetPassword}
                  />
                  <Route
                    module="admin.settings"
                    path="/users/settings"
                    component={UserSettings}
                  />
                  <Route
                    module="admin.permissions"
                    path="/permissions"
                    component={PermissionsList}
                  />
                  <Route
                    exact
                    path="/invitations"
                    component={InvitationsIndex}
                  />
                  <Route
                    path="/invitations/:id/details"
                    component={InvitationDetails}
                  />
                  <Route exact path="/recruiting">
                    <Redirect to="/vacancies" />
                  </Route>
                  <Route exact path="/applicants" component={ApplicantsIndex} />
                  <Route
                    path="/applicants/:id/details"
                    component={ApplicantDetails}
                  />
                  <Route
                    path="/applicants/:id/profile"
                    component={ApplicantProfile}
                  />
                  <Route exact path="/vacancies" component={VacanciesIndex} />
                  <Route
                    path="/vacancies/:id/details"
                    component={VacancyDetails}
                  />
                  <Route
                    path="/vacancies/:id/profile"
                    component={VacancyProfile}
                  />
                  <Route path="/vacancies/:id/match" component={VacancyMatch} />
                  <Route exact path="/seminars" component={SeminarsIndex} />
                  <Route
                    path="/seminars/:id/details"
                    component={SeminarDetails}
                  />
                  <Route
                    exact
                    path="/seminarTypes"
                    component={SeminarTypesIndex}
                  />
                  <Route
                    path="/seminarTypes/:id/details"
                    component={SeminarTypeDetails}
                  />
                  <Route exact path="/reports" component={ReportsIndex} />
                  <Route exact path="/messages" component={MessagesIndex} />
                  <Route exact path="/error" component={Error} />
                  <Redirect to="/" />
                </Switch>
              </div>
            </div>
          </main>

          <Footer />
        </Router>
      </IntlProvider>
    </ApolloProvider>
  );
};

export default App;
