import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import { useIntl } from "react-intl";

import { useMutation, useQuery } from "@apollo/client";
import { FilterIcon, PlusIcon } from "@heroicons/react/solid";

import { ResponsiveBar } from "@nivo/bar";

import { FILTER_SKILL_MANY, SKILL_CREATE_ONE } from "../../schema/skill";
import { Skill } from "../../types";

import FilterContext from "../../context/FilterContext";
import SliderOverContext from "../../context/SliderOverContext";
import useToggle from "../../hooks/useToogle";

import { currencyFormater } from "../../config/i18n";
import MetricTooltip from "../competences/MetricTooltip";
import { Error, Loading } from "../ui/Alerts";
import SearchInput from "../ui/SearchInput";
import SlideOver from "../ui/SlideOver";
import { H1, H3 } from "../ui/Typo/Typo";
import Filter, { FormData as FilterData } from "./Filter";
import Form, { FormData } from "./Form";
import List from "./List";
import Tabs from "./Tabs";

import { BlurOverlay, PermissionRequired } from "components/ui/permissions";
import { PermissionFlag, scope } from "types/permission";

export const SkillAxis = (tick: any) => {
  return (
    <g transform={`translate(${tick.x - 5},${tick.y})`}>
      <text
        textAnchor="end"
        className="truncate"
        dominantBaseline="middle"
        style={{
          fontSize: 10,
        }}
      >
        {tick.value}
      </text>
    </g>
  );
};

const Index = () => {
  const history = useHistory();
  const [query, setQuery] = useState("");
  const { formatMessage: f } = useIntl();

  const [open, toggle] = useToggle();
  const [openFilter, toggleFilter] = useToggle();

  const { loading, error, data, refetch } = useQuery(FILTER_SKILL_MANY, {
    variables: {
      name: query,
    },
    fetchPolicy: "network-only", // cache-and-network
  });

  const [createSkill] = useMutation(SKILL_CREATE_ONE, {
    refetchQueries: [
      {
        query: FILTER_SKILL_MANY,
        variables: {
          name: query,
        },
      },
    ],
  });

  //const { data: barData } = useQuery(EMPLOYEE_COMPETENCES);

  const barData =
    data?.skillMany
      .map((skill: Skill) => ({
        _id: skill._id,
        name: skill.name,
        supply: skill.supply,
        demand: skill.demand,
      }))
      .sort((a: Skill, b: Skill) => b.supply - a.supply)
      .slice(0, 10) || [];

  useEffect(() => {
    const listener = (event: KeyboardEvent) => {
      if (event.code === "Enter" || event.code === "NumpadEnter") {
        // setQuery(query);
        refetch();
      }
    };
    document.addEventListener("keydown", listener);
    return () => {
      document.removeEventListener("keydown", listener);
    };
  }, [query, refetch]);

  const onSubmit = (formData: FormData) => {
    createSkill({
      variables: {
        name: formData.name,
        description: formData.description,
        color: formData.color,
        costs: +formData.costs,
        expirationYear: +formData.expirationYear,
        skillTypeId: formData.skillTypeId,
        core: Boolean(formData.core),
        future: Boolean(formData.future),
        forEmployee: Boolean(formData.forEmployee),
        forManager: Boolean(formData.forManager),
      },
    });
    toggle();
  };

  const onSubmitFilter = (filterData: FilterData) => {
    refetch({ ...(filterData as any) });
    //toggleFilter();
  };

  const formattedKnowledgeValue = currencyFormater.format(
    data?.skillMany.reduce((memo: any, skill: Skill) => memo + skill.supply, 0)
  );

  return (
    <>
      {loading && <Loading />}
      {error && <Error error={error} />}

      <H3>Mit welchen Kompetenzen wird welcher Umsatz realisiert?</H3>

      <div className="pt-2 h-64 relative">
        <BlurOverlay module="competencies.overview_chart">
          <ResponsiveBar
            data={barData}
            keys={["supply", "demand"]}
            indexBy="name"
            groupMode="grouped"
            margin={{ top: 20, right: 50, bottom: 50, left: 60 }}
            theme={{
              background: "#f7fafc",
              tooltip: {
                container: {
                  background: "#fff",
                },
              },
            }}
            padding={0.3}
            colors={{ scheme: "nivo" }}
            // colors={(d) => d.data.color || "#fecd1b"}
            enableLabel={false}
            axisBottom={null}
            tooltip={(item: any) => (
              <div className="flex flex-col items-center text-center text-xs p-0.5">
                <span className="font-bold">{item.data.name}</span>
                <span className="font-semibold" style={{ color: item.color }}>
                  {item.id === "supply" ? "IST Umsatz" : "SOLL Umsatz"}
                </span>
                {currencyFormater.format(item.value)}
              </div>
            )}
            labelFormat={(v) => `${currencyFormater.format(+v)}`}
            labelTextColor="inherit:darker(1.4)"
            labelSkipWidth={16}
            labelSkipHeight={16}
            onClick={({ data }) => history.push(`/skills/${data._id}/details`)}
          />
        </BlurOverlay>
      </div>

      <PermissionRequired
        module="competencies.create"
        flag={PermissionFlag.WRITE}
        scopeRequired={scope.ALL}
      >
        <SliderOverContext.Provider value={{ open: open, toggle }}>
          <SlideOver title="Neue Kompetenzen hinzufügen">
            <Form onSubmit={onSubmit} />
          </SlideOver>
        </SliderOverContext.Provider>
      </PermissionRequired>

      <Tabs />

      {/* SectionHeader */}
      <div className="-ml-4 -mt-2 flex items-center justify-between flex-wrap sm:flex-no-wrap">
        <div className="ml-4 mt-2">
          <H1>{f({ id: "skills.name" })}</H1>
        </div>
        <PermissionRequired module="competencies.search">
          <div className="mt-2 ml-2 flex-grow">
            <SearchInput placeholder="Suche nach Name..." onChange={setQuery} />
          </div>
        </PermissionRequired>
        <div className="p-6 bg-white ml-4 mt-2 flex-shrink-0 space-x-4">
          <PermissionRequired
            module="competencies.create"
            flag={PermissionFlag.WRITE}
            scopeRequired={scope.ALL}
          >
            <span className="inline-flex rounded-md shadow-sm">
              <button
                type="button"
                className="relative inline-flex items-center px-4 py-2 border border-transparent text-sm leading-5 font-medium rounded-md text-gray bg-coral-300 hover:bg-coral-200 focus:outline-none focus:shadow-outline-indigo focus:border-orange-400 active:bg-coral-400"
                onClick={toggle}
              >
                <PlusIcon className="w-5 h-5 mr-1" />
                {f({ id: "skills.new" })}
              </button>
            </span>
          </PermissionRequired>
          <span className="inline-flex shadow-sm rounded-md">
            <PermissionRequired module="competencies.filter">
              <button
                type="button"
                className="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm leading-5 font-medium rounded-md text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 active:text-gray-800 active:bg-gray-50 transition duration-150 ease-in-out"
                onClick={toggleFilter}
              >
                <FilterIcon className="h-4 w-4 mr-1" />
                {f({ id: "actions.filter" })}
              </button>
            </PermissionRequired>
          </span>
        </div>
      </div>

      <div className="flex space-x-10 items-top">
        <div className="flex-1">
          <h2 className="text-gray-500 text-xs font-medium uppercase tracking-wide">
            <span className="border-r border-gray-300 py-0.5 px-4">
              {`${data?.skillMany.length} Einträge`}
            </span>
            <span className="pl-4">
              <MetricTooltip text={f({ id: "knowledgevalue.description" })}>
                <>{`${formattedKnowledgeValue} ${f({
                  id: "knowledgevalue",
                })}`}</>
              </MetricTooltip>
            </span>
          </h2>
          <List data={data?.skillMany} />
        </div>

        <PermissionRequired module="competencies.filter">
          <FilterContext.Provider
            value={{ open: openFilter, toggle: toggleFilter }}
          >
            <Filter onSubmit={onSubmitFilter} />
          </FilterContext.Provider>
        </PermissionRequired>
      </div>
    </>
  );
};

export default Index;
