import { Link } from "react-router-dom";
import { format, parseISO } from "date-fns";
import { de } from "date-fns/locale";
import { useLazyQuery } from "@apollo/client";
import { useIntl } from "react-intl";

import { FILTER_EMPLOYEE_MANY } from "../../schema/employee";
import { FILTER_JOB_MANY } from "../../schema/job";
import { FILTER_SEMINAR_MANY } from "../../schema/seminar";

import { Skill } from "../../types";
import { currencyFormater } from "../../config/i18n";

import { Loading, Error } from "../ui/Alerts";

import Arrow from "./Arrow";
import SliderOverContext from "../../context/SliderOverContext";
import EmployeeSlideOverList from "../employees/SliderOverList/SlideOverList";
import JobSlideOverList from "../jobs/SliderOverList/SlideOverList";
import SeminarSlideOverList from "../seminars/SliderOverList/SlideOverList";
import useToggle from "../../hooks/useToogle";
import { PermissionRequired } from "components/ui/permissions";

interface Props {
  skill: Skill;
}

const Item = ({ skill }: Props) => {
  const { formatMessage: f } = useIntl();
  const [employeeOpen, employeeToggle] = useToggle();
  const [jobOpen, jobToggle] = useToggle();
  const [seminarOpen, seminarToggle] = useToggle();

  const [
    getEmployees,
    { loading: employeeLoading, error: employeeError, data: employeeData },
  ] = useLazyQuery(FILTER_EMPLOYEE_MANY, {
    variables: {
      skillIds: [skill._id],
    },
  });
  const [getJobs, { loading: jobLoading, error: jobError, data: jobData }] =
    useLazyQuery(FILTER_JOB_MANY, {
      variables: {
        skillIds: [skill._id],
      },
    });
  const [
    getSeminars,
    { loading: seminarLoading, error: seminarError, data: seminarData },
  ] = useLazyQuery(FILTER_SEMINAR_MANY, {
    variables: {
      skillIds: [skill._id],
    },
  });

  const onClickEmployees = () => {
    employeeToggle();
    getEmployees({ variables: { skillIds: [skill._id] } });
  };

  const onClickJobs = () => {
    jobToggle();
    getJobs({ variables: { skillIds: [skill._id] } });
  };

  const onClickSeminars = () => {
    seminarToggle();
    getSeminars({ variables: { skillIds: [skill._id] } });
  };

  const formattedSupply = currencyFormater.format(skill.supply);
  const formattedDemand = currencyFormater.format(skill.demand);

  return (
    <>
      <tr>
        <td
          className="px-6 py-4"
          style={{
            borderLeft: 10,
            borderStyle: "solid",
            borderColor: skill.color || "transparent",
          }}
        >
          <div className="flex items-center">
            <div className="flex-shrink-0 h-10 w-4">
              <Arrow value={skill.supply} />
            </div>
            <Link to={`/skills/${skill._id}/details`} className="ml-4">
              <div className="text-sm font-medium text-gray-900">
                {skill.name}
              </div>
              <p className="text-sm text-gray-500 overflow-ellipsis">
                {skill.description}
              </p>
            </Link>
          </div>
        </td>
        <td className="px-6 py-4 whitespace-nowrap">
          {/* Employee SlideOver */}
          <SliderOverContext.Provider
            value={{ open: employeeOpen, toggle: employeeToggle }}
          >
            <EmployeeSlideOverList data={employeeData?.employeeMany}>
              {employeeLoading && <Loading />}
              {employeeError && <Error error={employeeError} />}
              Mitarbeiter mit Kompetenz
              <br />
              <mark>{skill.name}</mark>
            </EmployeeSlideOverList>
          </SliderOverContext.Provider>
          <button
            className="text-sm text-gray-900 cursor-pointer hover:underline disabled:opacity-50"
            disabled={skill.employeeCount == 0}
            onClick={onClickEmployees}
          >
            {skill.employeeCount} {f({ id: "employees.name" })}
          </button>
          <br />

          {/* Job SlideOver */}
          <SliderOverContext.Provider
            value={{ open: jobOpen, toggle: jobToggle }}
          >
            <JobSlideOverList data={jobData?.jobMany}>
              {jobLoading && <Loading />}
              {jobError && <Error error={jobError} />}
              Arbeitsplätze mit Kompetenz
              <br />
              <mark>{skill.name}</mark>
            </JobSlideOverList>
          </SliderOverContext.Provider>
          <button
            className="text-sm text-gray-900 cursor-pointer hover:underline disabled:opacity-50"
            disabled={skill.jobCount == 0}
            onClick={onClickJobs}
          >
            {skill.jobCount} {f({ id: "jobs.name" })}
          </button>
          <br />

          {/* Seminar SlideOver */}
          <SliderOverContext.Provider
            value={{ open: seminarOpen, toggle: seminarToggle }}
          >
            <SeminarSlideOverList data={seminarData?.seminarMany}>
              {seminarLoading && <Loading />}
              {seminarError && <Error error={seminarError} />}
              Seminare mit Kompetenz
              <br />
              <mark>{skill.name}</mark>
            </SeminarSlideOverList>
          </SliderOverContext.Provider>
          <button
            className="text-sm text-gray-900 cursor-pointer hover:underline disabled:opacity-50"
            disabled={skill.seminarCount == 0}
            onClick={onClickSeminars}
          >
            {skill.seminarCount} {f({ id: "seminars.name" })}
          </button>
        </td>
        <PermissionRequired module="competencies.value.employee">
          <td className="px-6 py-4 whitespace-nowrap">
            <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
              {formattedSupply}
            </span>
          </td>
        </PermissionRequired>
        <PermissionRequired module="competencies.value.workplace">
          <td className="px-6 py-4 whitespace-nowrap">
            <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-white text-green-800">
              {formattedDemand}
            </span>
          </td>
        </PermissionRequired>
        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
          {format(parseISO(skill.createdAt), "do MMMM", { locale: de })}
        </td>
      </tr>
    </>
  );
};

export default Item;
