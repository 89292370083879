import React from "react";
import Item from "./Item";

interface Props {
  data: Array<any>;
}

const List: React.FC<Props> = ({ data = [] }) => {
  return (
    <ul className="mt-3 grid grid-cols-1 gap-5 sm:gap-6 sm:grid-cols-2 lg:grid-cols-4">
      {data.map((item: any) => (
        <Item item={item} />
      ))}
    </ul>
  );
};

export default List;
