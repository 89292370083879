import { useForm, Controller } from "react-hook-form";
import { useIntl } from "react-intl";

import { Job, Competence, Revenue } from "../../types";
import { UnitSelect, JobDescriptionSelect } from "../common/form";
interface Props {
  onSubmit: (formData: any) => void;
  data?: Job;
}

export interface FormData {
  name: string;
  description: string;
  jobDescriptionId: string;
  salary: number;
  costs: number;
  competences: Array<Competence>;
  unitId: string;
  revenues: Array<Revenue>;
  fileIds: Array<string>;
}

const Form = ({ onSubmit, data }: Props) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
  } = useForm<FormData>({ defaultValues: data });

  const { formatMessage: f } = useIntl();

  return (
    <form onSubmit={handleSubmit(onSubmit)} id="slide-over-form">
      <div className="flex-1 flex flex-col justify-between">
        <div className="px-4 divide-y divide-gray-200 sm:px-6">
          <div className="space-y-6 pt-6 pb-5">
            <div className="space-y-1">
              <label
                htmlFor="name"
                className="block text-sm font-medium leading-5 text-gray-900"
              >
                Name
              </label>
              <input
                type="text"
                id="name"
                {...register("name", { required: true })}
                className="mt-1 focus:ring-orange-500 focus:border-orange-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
              />

              {errors.name && (
                <span className="text-sm font-light text-red-500">
                  Name fehlt
                </span>
              )}
            </div>
            <div className="space-y-1">
              <label
                htmlFor="description"
                className="block text-sm font-medium leading-5 text-gray-900"
              >
                Beschreibung
              </label>
              <div className="mt-1">
                <textarea
                  id="description"
                  {...register("description")}
                  className="shadow-sm focus:ring-orange-500 focus:border-orange-500 mt-1 block w-full sm:text-sm border-gray-300 rounded-md"
                ></textarea>
              </div>
              <p className="mt-2 text-sm text-gray-500">
                Kurze Beschreibung zu diesem Arbeitsplatz
              </p>
            </div>
            <div className="space-y-2">
              <label
                htmlFor="job-description-id"
                className="block text-sm font-medium leading-5 text-gray-900"
              >
                {f({ id: "jobDescriptions.name" })}
              </label>
              <Controller
                control={control}
                name="jobDescriptionId"
                render={({ field }) => <JobDescriptionSelect {...field} />}
              />
            </div>
            <div className="flex space-x-6">
              <div className="space-y-1">
                <label
                  htmlFor="salary"
                  className="block text-sm font-medium text-gray-700"
                >
                  {f({ id: "salary.name" })}
                </label>
                <input
                  type="number"
                  id="salary"
                  {...register("salary", {
                    required: true,
                    valueAsNumber: true,
                    min: 0,
                  })}
                  className="mt-1 focus:ring-orange-500 focus:border-orange-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                />
                {errors.salary && (
                  <span className="text-sm font-light text-red-500">
                    Gehalt fehlt
                  </span>
                )}
              </div>
              <div className="space-y-1">
                <label
                  htmlFor="costs"
                  className="block text-sm font-medium text-gray-700"
                >
                  Kosten
                </label>
                <input
                  type="number"
                  id="costs"
                  {...register("costs", { valueAsNumber: true })}
                  className="mt-1 focus:ring-orange-500 focus:border-orange-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                />
                {errors.costs && (
                  <span className="text-sm font-light text-red-500">
                    Kosten fehlerhaft
                  </span>
                )}
              </div>
            </div>
            <div className="space-y-2">
              <label
                htmlFor="unit-id"
                className="block text-sm font-medium leading-5 text-gray-900"
              >
                Einheit
              </label>
              <Controller
                control={control}
                name="unitId"
                render={({ field }) => <UnitSelect {...field} />}
              />
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};

export default Form;
