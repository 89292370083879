import { ReactElement, useState, useEffect } from "react";
import { useLazyQuery } from "@apollo/client";
import { useForm, FormProvider } from "react-hook-form";

// import { DevTool } from "@hookform/devtools";

import {
  HorizontalNavigation,
  HorizontalNavigationOption,
} from "../ui/navigation/HorizontalNavigation";

import {
  AddressField,
  SalaryField,
  DateField,
  FirstNameField,
  LastNameField,
  PersonnalNumberField,
  PhotoField,
  GenderField,
  EmailField,
  JobField,
  UnitField,
  ManagerField,
  EmploymentTypeField,
  EmploymentStatusField,
} from "../common/form";

import { JOB_BY_ID } from "../../schema/job";

import {
  Employee,
  EmploymentType,
  EmploymentStatus,
  Competence,
  Revenue,
  Address,
} from "../../types";

import { Divider } from "../ui/layout/Divider";

const tabs: HorizontalNavigationOption[] = [
  { value: "employee", label: "Mitarbeiter" },
  { value: "org", label: "Unternehmen" },
  { value: "address", label: "Anschrift" },
];

interface Props {
  onSubmit: (formData: any) => void;
  data?: Employee;
}
export interface FormData {
  firstName: string;
  lastName: string;
  gender: string;
  email: string;
  phone: string;
  address: Address;
  photo: string;
  birthDate: Date;
  hireDate: Date;
  retireDate: Date;
  terminationDate: Date;
  personnalNumber: string;
  jobId: string;
  salary: number;
  competences: Array<Competence>;
  unitId: string;
  managerId: string;
  employmentType: EmploymentType;
  employmentStatus: EmploymentStatus;
  revenues: Array<Revenue>;
  fileIds: Array<string>;
}

const Form = ({ onSubmit, data }: Props) => {
  const methods = useForm<FormData>({ defaultValues: data });

  const { handleSubmit, control, watch, getValues, setValue } = methods;

  const selectedJobId = watch("jobId");

  const [getJob, { called: jobCalled, data: jobData }] = useLazyQuery(
    JOB_BY_ID,
    {
      variables: {
        _id: selectedJobId,
      },
    }
  );

  const [selectedTab, setSelectedTab] = useState(tabs[0]);

  let tab: ReactElement;
  switch (selectedTab.value) {
    case "address":
      tab = <AddressField />;
      break;
    case "org":
      tab = (
        <>
          <div className="space-y-1">
            <JobField />
          </div>
          <div className="space-y-1">
            <UnitField />
          </div>
          <div className="py-1">
            <Divider />
          </div>
          <div className="flex space-x-6">
            <div className="w-1/2 space-y-1">
              <ManagerField />
            </div>
            <div className="w-1/2 space-y-1">
              <PersonnalNumberField />
            </div>
          </div>
          <div className="flex space-x-6">
            <div className="w-1/2 space-y-1">
              <DateField name="hireDate" label="Einstellungsdatum" />
            </div>
            <div className="w-1/2 space-y-1">
              <SalaryField />
            </div>
          </div>
          <div className="flex space-x-6">
            <div className="w-1/2 space-y-1">
              <EmploymentTypeField />
            </div>
            <div className="w-1/2 space-y-1">
              <EmploymentStatusField />
            </div>
          </div>
        </>
      );
      break;
    case "employee":
      tab = (
        <>
          <div className="flex space-x-6">
            <PhotoField />
          </div>
          <div className="flex space-x-6">
            <div className="space-y-1">
              <FirstNameField />
            </div>
            <div className="space-y-1">
              <LastNameField />
            </div>
          </div>
          <div className="flex space-x-6">
            <div className="w-1/2 space-y-1">
              <GenderField />
            </div>
            <div className="w-1/2 space-y-1">
              <EmailField />
            </div>
          </div>
          <div className="py-1">
            <Divider />
          </div>
          <div className="space-y-1">
            <DateField name="birthDate" label="Geburtsdatum" />
          </div>
          <div className="flex space-x-6">
            <div className="space-y-1">
              <DateField name="terminationDate" label="Vertragsendedatum" />
            </div>
            <div className="space-y-1">
              <DateField name="retireDate" label="Rentendatum" />
            </div>
          </div>
        </>
      );
      break;
    default:
      throw Error("Tab unreachable");
  }

  useEffect(() => {
    // for new employee records only
    if (!data?._id && selectedJobId) {
      //console.log("JobId changed to", selectedJobId);
      getJob({ variables: { _id: selectedJobId } });
    }
  }, [data, selectedJobId]);

  useEffect(() => {
    // job data fetched
    if (jobData?.jobById) {
      // filter out unwanted attributes
      const competences = jobData.jobById?.competences.map((c: Competence) => ({
        skillId: c.skillId,
        value: c.value,
      }));
      // assign competences from job to employee
      setValue("competences", competences, {
        shouldValidate: false,
      });
    }
  }, [jobCalled, jobData]);

  return (
    <>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)} id="slide-over-form">
          <div className="flex-1 flex flex-col justify-between">
            <div className="px-4 divide-y divide-gray-200 sm:px-6">
              <div className="space-y-6 pt-6 pb-5">
                <HorizontalNavigation
                  options={tabs}
                  selected={selectedTab}
                  onSelect={setSelectedTab}
                />
                {tab}
              </div>
            </div>
          </div>
        </form>
      </FormProvider>
    </>
  );
};

export default Form;
