import React from "react";
import { useIntl } from "react-intl";
import { keys, filter, pipe, map } from "ramda";

import { PermissionFlag } from "types/permission";

const getKeys = pipe(
  keys,
  map((k: string) => parseInt(k, 10)),
  filter((k: number) => !isNaN(k)),
);

interface IProps {
  value: PermissionFlag;
  disabled?: boolean;
  onChange?: (e: React.ChangeEvent<HTMLSelectElement>) => void;
};

export const PermissionSelector: React.FC<IProps> = ({
  value,
  disabled = false,
  onChange = () => {},
}) => {
  const { formatMessage: f } = useIntl();

  const getTranslation = (flag?: string): string => {
    if (!flag) { return "---"; }
    return f({ id: `permissions.flags.${flag.toLowerCase()}` });
  };

  return (
    <select value={value} onChange={onChange} disabled={disabled}>
    {
      getKeys(PermissionFlag).map((k: number, idx: number) => (
        <option key={idx} value={k}>{getTranslation(PermissionFlag[k])}</option>
      ))
    }
    </select>
  );
};
