import { useParams, useHistory, RouteComponentProps } from "react-router-dom";
import { useQuery, useMutation } from "@apollo/client";
import { useIntl } from "react-intl";
import { Disclosure } from "@headlessui/react";
import { ChevronUpIcon } from "@heroicons/react/solid";
import { ResponsiveLine } from "@nivo/line";

import {
  SKILL_BY_ID,
  SKILL_UPDATE_BY_ID,
  SKILL_REMOVE_BY_ID,
  SKILL_MANY,
} from "../../schema/skill";

import { METRIC_SKILLS_BY_DATE } from "../../schema/metric";
import { FILTER_EMPLOYEE_MANY } from "../../schema/employee";
import { FILTER_JOB_MANY } from "../../schema/job";
import { FILTER_SEMINAR_MANY } from "../../schema/seminar";

import SliderOverContext from "../../context/SliderOverContext";
import useToggle from "../../hooks/useToogle";

import { Loading, Error } from "../ui/Alerts";
import SlideOver from "../ui/SlideOver";
import PageHeader from "../ui/Headings/PageHeader";
import { Breadcrumb } from "../ui/navigation/Breadcrumb";

import Form, { FormData } from "./Form";
import { Divider } from "../ui/layout/Divider";
import { H2 } from "../ui/Typo/Typo";
import Stats from "./Stats";
import EmployeeList from "../employees/List";
import JobList from "../jobs/List";
import SeminarList from "../seminars/List";
import MetricTooltip from "../competences/MetricTooltip";

import { currencyFormater } from "../../config/i18n";

interface ParamTypes {
  id: string;
}

const Details = ({}: RouteComponentProps) => {
  const { id } = useParams<ParamTypes>();
  const history = useHistory();
  const [open, toggle] = useToggle();
  const { formatMessage: f } = useIntl();

  const { loading, error, data } = useQuery(SKILL_BY_ID, {
    variables: {
      _id: id,
    },
  });
  const [updateSkill] = useMutation(SKILL_UPDATE_BY_ID, {
    refetchQueries: [{ query: SKILL_MANY }],
  });

  const [deleteSkill] = useMutation(SKILL_REMOVE_BY_ID, {
    refetchQueries: [{ query: SKILL_MANY }],
  });

  const { data: chartData } = useQuery(METRIC_SKILLS_BY_DATE, {
    variables: {
      skillId: id,
    },
  });

  const { data: employeeData } = useQuery(FILTER_EMPLOYEE_MANY, {
    variables: {
      skillIds: [id],
    },
  });
  const { data: jobData } = useQuery(FILTER_JOB_MANY, {
    variables: {
      skillIds: [id],
    },
  });
  const { data: seminarData } = useQuery(FILTER_SEMINAR_MANY, {
    variables: {
      skillIds: [id],
    },
  });

  const onUpdate = (formData: FormData) => {
    updateSkill({
      variables: {
        _id: id,
        name: formData.name,
        description: formData.description,
        color: formData.color,
        costs: +formData.costs,
        expirationYear: +formData.expirationYear,
        skillTypeId: formData.skillTypeId,
        core: Boolean(formData.core),
        future: Boolean(formData.future),
        forEmployee: Boolean(formData.forEmployee),
        forManager: Boolean(formData.forManager),
      },
    });
    history.goBack();
  };

  const onDelete = () => {
    deleteSkill({ variables: { _id: id } });
    history.goBack();
  };

  if (loading) return <Loading />;
  if (error) return <Error error={error} />;

  const supplyData = chartData?.metricSkillsByDate.map((d: any) => {
    return { x: d.date, y: d.values.supply };
  });
  const demandData = chartData?.metricSkillsByDate.map((d: any) => {
    return { x: d.date, y: d.values.demand };
  });
  const groupedData = [
    {
      id: f({ id: "knowledgevalue.employees" }),
      data: supplyData || [],
    },
    {
      id: f({ id: "knowledgevalue.jobs" }),
      data: demandData || [],
    },
  ];

  return (
    <>
      <SliderOverContext.Provider value={{ open: open, toggle }}>
        <SlideOver title="Kompetenz bearbeiten">
          <Form onSubmit={onUpdate} data={data.skillById} />
        </SlideOver>
        <PageHeader
          title={data?.skillById?.name}
          onUpdate={toggle}
          onDelete={onDelete}
        >
          <Breadcrumb>
            <Breadcrumb.Item url="/">Home</Breadcrumb.Item>
            <Breadcrumb.Item url="/skills">
              {f({ id: "skills.name" })}
            </Breadcrumb.Item>
            <Breadcrumb.Item>{data?.skillById?.name}</Breadcrumb.Item>
          </Breadcrumb>
        </PageHeader>

        <p className="px-6 mt-1 max-w-2xl text-sm leading-5 text-gray-500">
          {data.skillById.description}
        </p>

        <Stats
          supply={data?.skillById?.projectedSupply}
          demand={data?.skillById?.projectedDemand}
        />

        <div className="h-80 mb-6">
          <ResponsiveLine
            data={groupedData}
            margin={{ top: 50, right: 50, bottom: 50, left: 80 }}
            xScale={{
              type: "time",
              format: "%Y-%m-%d",
              useUTC: false,
              precision: "day",
            }}
            xFormat="time:%Y-%m-%d"
            yScale={{
              type: "linear",
              stacked: false,
            }}
            yFormat=">-.2f"
            axisTop={null}
            axisRight={null}
            axisBottom={{
              format: "%b",
              tickValues: "every 4 weeks",
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 0,
              legend: "Zeitverlauf",
              legendPosition: "middle",
              legendOffset: 36,
            }}
            axisLeft={{
              tickSize: 10,
              tickPadding: 5,
              tickRotation: 0,
              legend: "Wert in €",
              legendPosition: "middle",
              legendOffset: -70,
            }}
            curve="monotoneX"
            enablePointLabel={false}
            enableSlices="x"
            sliceTooltip={({ slice }) => {
              return (
                <div className="flex flex-col items-center bg-white shadow-sm text-xs rounded py-1 px-2">
                  {slice.points.map((point) => (
                    <div
                      key={point.id}
                      style={{ color: point.serieColor }}
                      className="leading-normal"
                    >
                      <span>{point.serieId}</span>{" "}
                      <span className="font-bold">
                        {currencyFormater.format(+point.data.yFormatted)}
                      </span>
                    </div>
                  ))}
                </div>
              );
            }}
            pointColor={{ theme: "background" }}
            pointBorderColor={{ from: "serieColor" }}
            useMesh={true}
            enableArea={false}
            legends={[
              {
                anchor: "top",
                direction: "row",
                justify: false,
                translateY: -30,
                itemsSpacing: 0,
                itemDirection: "left-to-right",
                itemWidth: 250,
                itemHeight: 20,
                itemOpacity: 0.75,
                symbolSize: 12,
                symbolShape: "circle",
                symbolBorderColor: "rgba(0, 0, 0, .5)",
                effects: [
                  {
                    on: "hover",
                    style: {
                      itemBackground: "rgba(0, 0, 0, .03)",
                      itemOpacity: 1,
                    },
                  },
                ],
              },
            ]}
          />
        </div>

        <table className="my-6 min-w-full divide-y divide-gray-200">
          <thead className="bg-gray-100">
            <tr>
              <th
                scope="col"
                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                <MetricTooltip
                  text={f({ id: "knowledgevalue.employees.description" })}
                >
                  <>{f({ id: "knowledgevalue.employees" })}</>
                </MetricTooltip>
              </th>
              <th
                scope="col"
                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                <MetricTooltip
                  text={f({ id: "knowledgevalue.employees.mean.description" })}
                >
                  <>{f({ id: "knowledgevalue.employees.mean" })}</>
                </MetricTooltip>
              </th>
              <th
                scope="col"
                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                <MetricTooltip
                  text={f({ id: "knowledgevalue.jobs.description" })}
                >
                  <>{f({ id: "knowledgevalue.jobs" })}</>
                </MetricTooltip>
              </th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            <tr>
              <td className="px-6 py-4">
                {currencyFormater.format(data?.skillById?.supply)}
              </td>
              <td className="px-6 py-4">
                {currencyFormater.format(
                  data?.skillById?.supply / data?.skillById?.employeeCount
                )}
              </td>
              <td className="px-6 py-4">
                {currencyFormater.format(data?.skillById?.demand)}
              </td>
            </tr>
          </tbody>
        </table>

        <Divider>
          <Divider.Content>Weitere Ergebnisse</Divider.Content>
        </Divider>

        <div className="p-6">
          <H2>{f({ id: "employees.name" })}</H2>
          <Disclosure>
            {({ open }) => (
              <>
                <Disclosure.Button className="flex justify-between w-full px-4 py-2 text-sm font-medium text-left text-gray-900 bg-gray-200 rounded-md hover:bg-gray-300 focus:outline-none focus-visible:ring focus-visible:ring-gray-500 focus-visible:ring-opacity-75">
                  <span>{`${employeeData?.employeeMany.length} Einträge`}</span>
                  <ChevronUpIcon
                    className={`${
                      open ? "transform rotate-180" : ""
                    } w-5 h-5 text-gray-500`}
                  />
                </Disclosure.Button>
                <Disclosure.Panel className="px-4 pt-4 pb-2 text-sm text-gray-500">
                  <EmployeeList data={employeeData?.employeeMany} />
                </Disclosure.Panel>
              </>
            )}
          </Disclosure>
        </div>

        <Divider />

        <div className="p-6">
          <H2>{f({ id: "jobs.name" })}</H2>
          <Disclosure>
            {({ open }) => (
              <>
                <Disclosure.Button className="flex justify-between w-full px-4 py-2 text-sm font-medium text-left text-gray-900 bg-gray-200 rounded-md hover:bg-gray-300 focus:outline-none focus-visible:ring focus-visible:ring-gray-500 focus-visible:ring-opacity-75">
                  <span>{`${jobData?.jobMany.length} Einträge`}</span>
                  <ChevronUpIcon
                    className={`${
                      open ? "transform rotate-180" : ""
                    } w-5 h-5 text-gray-500`}
                  />
                </Disclosure.Button>
                <Disclosure.Panel className="px-4 pt-4 pb-2 text-sm text-gray-500">
                  <JobList data={jobData?.jobMany} />
                </Disclosure.Panel>
              </>
            )}
          </Disclosure>
          <h2 className="mt-6 text-gray-500 text-xs font-medium uppercase tracking-wide"></h2>
        </div>

        <Divider />

        <div className="p-6">
          <H2>{f({ id: "seminars.name" })}</H2>
          <h2 className="mt-6 text-gray-500 text-xs font-medium uppercase tracking-wide">
            {`${seminarData?.seminarMany.length} Einträge`}
          </h2>
          <SeminarList data={seminarData?.seminarMany} />
        </div>
      </SliderOverContext.Provider>
    </>
  );
};

export default Details;
