import { Link } from "react-router-dom";
import { format, parseISO } from "date-fns";
import { CheckIcon, ClockIcon } from "@heroicons/react/solid";

import { de } from "date-fns/locale";
import { Invitation, InvitationStatus } from "../../types";

interface Props {
  invitation: Invitation;
}

const Item = ({ invitation }: Props) => {
  const renderStatus = () => {
    switch (invitation.status.toString()) {
      case "sent":
        return <ClockIcon className="w-5 h-5" />;
      case "accepted":
        return <CheckIcon className="w-5 h-5" />;
      default:
        break;
    }
  };

  return (
    <tr>
      <td className="px-6 py-4">
        <div className="flex items-center">
          <Link to={`/invitations/${invitation._id}/details`} className="ml-4">
            <div
              className={`${
                invitation.status.toString() === "accepted" && "line-through"
              } text-sm font-mono text-gray-900`}
            >
              {invitation.signupToken}
            </div>
          </Link>
        </div>
      </td>
      <td className="px-6 py-4 whitespace-nowrap">
        <Link to={`/employees/${invitation.employeeId}/details`}>
          <span className="text-sm font-medium text-gray-900">
            {invitation.employee.firstName} {invitation.employee.lastName}
          </span>
        </Link>
      </td>
      <td className="px-6 py-4 whitespace-nowrap">
        <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
          {invitation.role?.name}
        </span>
      </td>
      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
        {invitation.createdAt &&
          format(parseISO(invitation.createdAt), "do MMMM HH:mm", {
            locale: de,
          })}
      </td>
      <td className="px-6 py-4 whitespace-nowrap">
        <span className="text-sm font-medium text-gray-900">
          {renderStatus()}
        </span>
      </td>
    </tr>
  );
};

export default Item;
